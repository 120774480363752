import {
  getFinneoClients, getScheduleBClients,
} from 'actions/owners';

const myClients = () => {
  const initialState = {
    finneoClients: [],
    scheduleBClients: [],
  };

  return (state = initialState, { type, response }) => {
    switch (type) {
      case getFinneoClients.requestTypes.SUCCESS:
        return {
          ...state,
          finneoClients: response.data || response,
          totalPages: response.totalPages || 0,
          page: response.page || 0,
        };
      case getScheduleBClients.requestTypes.SUCCESS:
        return {
          ...state,
          schedBClients: response.data || response,
          totalPages: response.totalPages || 0,
          page: response.page || 0,
        };
      default:
        return state;
    }
  };
};

export default myClients();
