import React, { useState, useEffect } from 'react';
import { SvgIcons, Sider, UserCompnayLogo } from 'components';
import { Menu, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import { getUser } from 'selectors/users';
import { getAllDeals } from 'selectors/deals';
import { useSelector, useDispatch } from 'react-redux';
import { isUserPermitted } from 'configs/isUserPermitted';
import { fetchAllDeals } from '../../actions/deals';
import { roles } from '../../constants/users';

const { SubMenu } = Menu;

const MainMenu = ({ history, location }) => {
  const MenuItems = [];
  const [isLogoVisible, setLogoVisible] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const {
    data: {
      roles: currentRole,
      user_setting: currentUserSettings,
    },
  } = useSelector(getUser);
  const dispatch = useDispatch();
  const deals = useSelector(getAllDeals);

  useEffect(() => {
    dispatch(fetchAllDeals.request({ getStatusAll: true }));
  }, [dispatch]);

  isUserPermitted(
    [
      roles.ROLE_TRANSACTION_ONLY,
    ],
    currentRole,
  )
    && MenuItems.push({
      iconcomponent: SvgIcons.portfolioSvg,
      name: 'Dashboard',
      key: '1',
      isSubMenu: true,
      items: [
        {
          name: 'Management Dashboard',
          key: '/admin-dashboard',
          badge: 'dot',
          path: '/admin-dashboard',
          breadcrumbName: 'Admin Dashboard',
          isSubMenu: true,
        },
        {
          name: 'CRM',
          key: '/crm-dashboard',
          path: '/crm-dashboard',
          isSubMenu: true,
        },
        {
          name: 'ICP',
          key: '/lead-assignment',
          path: '/lead-assignment',
          isSubMenu: true,
        },
      ],
    });

  isUserPermitted(
    [
      roles.ROLE_TO_UNDERWRITER,
    ],
    currentRole,
  )
    && MenuItems.push({
      iconcomponent: SvgIcons.portfolioSvg,
      name: 'Dashboard',
      key: '1',
      isSubMenu: true,
      breadcrumbName: 'Dashboard',
      items: [
        {
          name: 'Manager Dashboard',
          key: '/manager-dashboard',
          badge: 'dot',
          path: '/manager-dashboard',
          breadcrumbName: 'Manager Dashboard',
          isSubMenu: true,
        },
        {
          name: 'CRM',
          key: '/crm-dashboard',
          path: '/crm-dashboard',
          isSubMenu: true,
        },
      ],
    });

  isUserPermitted(
    [
      roles.ROLE_TO_AGENT,
    ],
    currentRole,
  )
    && MenuItems.push({
      iconcomponent: SvgIcons.portfolioSvg,
      name: 'Dashboard',
      key: '1',
      isSubMenu: true,
      breadcrumbName: 'Dashboard',
      items: [
        {
          name: 'Originator Dashboard',
          key: '/originator-dashboard',
          badge: 'dot',
          path: '/originator-dashboard',
          breadcrumbName: 'Originator Dashboard',
          isSubMenu: true,
        },
      ],
    });
  isUserPermitted(
    [
      roles.ROLE_CAPSTACK,
      roles.ROLE_FULL_SERVER,
      roles.ROLE_FS_UNDERWRITER,
      roles.ROLE_FS_AGENT,
      roles.ROLE_DASHBOARD_ONLY],
    currentRole,
  )
    && MenuItems.push({
      iconcomponent: SvgIcons.portfolioSvg,
      name: 'Portfolio',
      key: '2',
      isSubMenu: true,
      items: [
        {
          name: 'Main Dashboard',
          path: '/',
          key: '/',
          isSubMenu: true,
        },
        {
          name: 'Manage Properties',
          path: '/manage-properties',
          key: '/manage-properties',
          isSubMenu: true,
        },
        {
          name: 'Reporting',
          path: '/reporting',
          key: '/reporting',
          isSubMenu: true,
        },
      ],
    });

  isUserPermitted(
    [
      roles.ROLE_CAPSTACK,
      roles.ROLE_SELF_SERVE,
      roles.ROLE_FULL_SERVER,
      roles.ROLE_FS_UNDERWRITER,
      roles.ROLE_FS_AGENT,
      roles.ROLE_TRANSACTION_ONLY,
      roles.ROLE_TO_AGENT,
      roles.ROLE_TO_UNDERWRITER,
    ],
    currentRole,
  )
    && MenuItems.push({
      iconcomponent: SvgIcons.dealSvg,
      name: 'Deals',
      key: '3',
      isSubMenu: true,
      items: [
        {
          name: `Draft Deals ${deals.draftDeals ? `(${deals.draftDeals})` : ''}`,
          path: '/deals/draft',
          key: '/deals/draft',
          isSubMenu: true,
        },
        {
          name: `Pending ${deals.pendingDeals ? `(${deals.pendingDeals})` : ''}`,
          path: '/deals/pending',
          key: '/deals/pending',
          isSubMenu: true,
        },
        {
          name: `WIP ${deals.inProgressDeals ? `(${deals.inProgressDeals})` : ''}`,
          path: '/deals/in-progress',
          key: '/deals/in-progress',
          isSubMenu: true,
        },
        {
          name: `Closed Deals ${deals.archivedDeals ? `(${deals.archivedDeals})` : ''}`,
          path: '/deals/archived',
          key: '/deals/archived',
          isSubMenu: true,
        },
        {
          name: `Archived Deals ${deals.inActiveDeals ? `(${deals.inActiveDeals})` : ''}`,
          path: '/deals/inactive',
          key: '/deals/inactive',
          isSubMenu: true,
        },
      ],
    });

  isUserPermitted(
    [
      roles.ROLE_CAPSTACK,
      roles.ROLE_FULL_SERVER,
      roles.ROLE_FS_UNDERWRITER,
      roles.ROLE_TRANSACTION_ONLY,
      roles.ROLE_TO_UNDERWRITER,
      roles.ROLE_TO_AGENT,
    ],
    currentRole,
  )
    && (currentUserSettings.accessLendersIntermediate || currentUserSettings.accessLendersAdvanced)
    && MenuItems.push(
      {
        iconcomponent: SvgIcons.lenderSvg,
        name: 'Lenders',
        key: '4',
        isSubMenu: true,
        items: [
          {
            name: 'Canada',
            path: '/lenders/list/ca',
            key: '/lenders/list/ca',
            isSubMenu: true,
          },
          {
            name: 'US',
            path: '/lenders/list/us',
            key: '/lenders/list/us',
            isSubMenu: true,
          },
        ],
      },
    );

  isUserPermitted(
    [
      roles.ROLE_CAPSTACK,
      roles.ROLE_FULL_SERVER,
      roles.ROLE_FS_UNDERWRITER,
      roles.ROLE_TRANSACTION_ONLY,
      roles.ROLE_TO_UNDERWRITER,
      roles.ROLE_TO_AGENT,
    ],
    currentRole,
  )
      && (currentUserSettings.accessConsultantsIntermediate || currentUserSettings.accessConsultantsAdvanced)
      && MenuItems.push(
        {
          iconcomponent: SvgIcons.consultantsSvg,
          name: 'Consultants',
          key: '/consultants',
          path: '/consultants',
          isSubMenu: false,
        },
      );

  // free features
  MenuItems.push(
    {
      iconcomponent: SvgIcons.servicesSvg,
      name: 'Services',
      key: '6',
      isSubMenu: true,
      items: [
        {
          name: 'Drone',
          path: '/service/drone',
          key: '/service/drone',
          isSubMenu: true,
        },
        {
          name: 'Lease',
          path: '/service/lease',
          key: '/service/lease',
          isSubMenu: true,
        },
        {
          name: 'Inspections',
          path: '/service/inspection',
          key: '/service/inspection',
          isSubMenu: true,
        },
      ],
    },
    {
      iconcomponent: SvgIcons.marketDataSvg,
      name: 'Market Data',
      key: '/bonds-rates',
      path: '/bonds-rates',
      isSubMenu: false,
    },
    {
      iconcomponent: SvgIcons.documentsSvg,
      name: 'Documents',
      key: '/documents',
      path: '/documents',
      isSubMenu: false,
    },
    ...(isUserPermitted([roles.ROLE_FS_AGENT], currentRole) ? [] : [
      {
        iconcomponent: SvgIcons.trainingSvg,
        name: 'Tutorials',
        key: '/tutorials',
        path: '/tutorials',
        isSubMenu: false,
      },
    ]),
    {
      iconcomponent: SvgIcons.calcSvg,
      name: 'Calculators',
      key: '/calculators',
      path: '/calculators',
      isSubMenu: false,
    },
  );

  let currentItem = ''; // default Main Dashboard'

  // const closeCollapse = () => {
  //   setCollapsed(true);
  //   setLogoVisible(false);
  // };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    setLogoVisible(!isLogoVisible);
  };
  const getKeys = (type) => {
    if (type === 'open') {
      const key = currentItem.key ? currentItem.key[0] : '';
      return [key];
    }
    if (type === 'select') {
      return [location.pathname];
    }
    return '';
  };

  const changeRoute = (item) => {
    // if (item.isSubMenu) {
    //   if (
    //     (item.key === '/lenders/list/ca' || item.key === '/lenders/list/us')
    //     && !collapsed
    //   ) {
    //     closeCollapse();
    //   }
    // }
    history.push(item.path);
  };

  const ListItems = MenuItems.map((menuitem) => {
    if (
      !menuitem.isSubMenu
      && location.pathname.includes(menuitem.path)
      && location.pathname !== '/'
    ) {
      currentItem = menuitem;
    }

    return menuitem.isSubMenu ? (
      <SubMenu
        key={menuitem.key}
        title={
          <span>
            <Icon
              component={menuitem.iconcomponent}
              style={{ color: '#fff', fontSize: '17px' }}
            />
            <span>{menuitem.name}</span>
          </span>
        }
      >
        {menuitem.items.map((item) => {
          if (
            location.pathname.includes(item.path)
            && location.pathname !== '/'
          ) {
            currentItem = item;
          }
          return (
            <Menu.Item onClick={() => changeRoute(item)} key={item.key}>
              {
              item.name === 'Reporting'
                ? <div style={{ wordSpacing: '10px' }}> {item.name}  <strong style={{ fontSize: '9px' }}> BETA</strong></div>
                : item.name
              }

            </Menu.Item>
          );
        })}
      </SubMenu>
    ) : (
      <Menu.Item onClick={() => changeRoute(menuitem)} key={menuitem.key}>
        <span>
          <Icon
            component={menuitem.iconcomponent}
            style={{ color: '#fff', fontSize: '17px' }}
            />
          <span>{menuitem.name}</span>
        </span>
      </Menu.Item>
    );
  });

  return (
    <Sider width={250} trigger={null} collapsible collapsed={!collapsed}>
      {!isLogoVisible && <UserCompnayLogo />}
      <Menu
        theme='dark'
        mode='inline'
        selectedKeys={getKeys('select')}
        defaultOpenKeys={getKeys('open')}
      >
        {ListItems}
        <Menu.Item onClick={toggleCollapse}>
          <span>
            <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />{' '}
            <span>Collapse menu</span>
          </span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default withRouter(MainMenu);
