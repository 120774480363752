/* eslint-disable no-nested-ternary */

import React, { useState } from 'react';
import {
  Row, Col, Form, Input,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  FormItem, Title, Card, Button,
  TabsAntD,
} from 'components';
import {
  updateConstructionLoan, getConstructionLoanDetails,
} from 'actions/construction';
import moment from 'moment';
import { loansValues, updateLoan } from 'actions/loans';
import { amortSchedCA } from '../../utils/calculatorScripts/amortCA';
import { checkNum } from '../../utils/formInputFormatter';

// Items associated with data.originalAmount belong to term loans
// and those with data.authorizedLoanAmount belong to construction loans

const formatItems = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

function CarouselCard({
  data, lenders, id, index, form, userId, loanType, propertyDetails, lenderContacts,
}) {
  const {
    allInRate, originalLoanAmount, amortization, term, firstPaymentDate,
  } = data;

  const { getFieldDecorator } = form;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [showNotesForm, setShowNotesForm] = useState(false);

  const { TabPane } = TabsAntD;
  const monthDiff = (d1, d2) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    // If the start date of the loan is in future, return 0 for the difference:
    return months <= 0 ? 0 : months;
  };

  function editableNotes() {
    setShowNotesForm(true);
  }

  function handleLoanNoteSubmit(loanData) {
    form.validateFields(async (err, values) => {
      if (!err) {
        const modifiedValues = {
          ...loanData,
          notes: values.loanNote,
        };
        if (loanType === 'construction') {
          await dispatch(updateConstructionLoan.request(modifiedValues));
          await dispatch(getConstructionLoanDetails.request({ userId }));
        } else if (loanType === 'term') {
          await dispatch(updateLoan.request(modifiedValues));
          await dispatch(loansValues.request({ userId }));
        }
        setShowNotesForm(false);
      }
    });
  }

  function handleCovNoteSubmit(loanData) {
    form.validateFields(async (err, values) => {
      if (!err) {
        const modifiedValues = {
          ...loanData,
          covenants: {
            ...loanData.covenants,
            notes: values.covenantsNotes,
          },
        };
        if (loanType === 'construction') {
          await dispatch(updateConstructionLoan.request(modifiedValues));
          await dispatch(getConstructionLoanDetails.request({ userId }));
        } else if (loanType === 'term') {
          await dispatch(updateLoan.request(modifiedValues));
          await dispatch(loansValues.request({ userId }));
        }
        setShowNotesForm(false);
      }
    });
  }

  function handleFeesNoteSubmit(loanData) {
    form.validateFields(async (err, values) => {
      if (!err) {
        const modifiedValues = {
          ...loanData,
          feesAndPricingDetails: {
            ...loanData.feesAndPricingDetails,
            notes: values.feesAndPricingNotes,
          },
        };
        if (loanType === 'construction') {
          await dispatch(updateConstructionLoan.request(modifiedValues));
          await dispatch(getConstructionLoanDetails.request({ userId }));
        } else if (loanType === 'term') {
          await dispatch(updateLoan.request(modifiedValues));
          await dispatch(loansValues.request({ userId }));
        }
        setShowNotesForm(false);
      }
    });
  }
  function handleRepNoteSubmit(loanData) {
    form.validateFields(async (err, values) => {
      if (!err) {
        const modifiedValues = {
          ...loanData,
          reportingObligation: {
            ...loanData.reportingObligation,
            notes: values.reportingNotes,
          },
        };
        if (loanType === 'construction') {
          await dispatch(updateConstructionLoan.request(modifiedValues));
          await dispatch(getConstructionLoanDetails.request({ userId }));
        } else if (loanType === 'term') {
          await dispatch(updateLoan.request(modifiedValues));
          await dispatch(loansValues.request({ userId }));
        }
        setShowNotesForm(false);
      }
    });
  }
  function handleGuarantorNoteSubmit(loanData) {
    form.validateFields(async (err, values) => {
      if (!err) {
        const modifiedValues = {
          ...loanData,
          guarantorNotes: values.notesGuarantor,
        };
        if (loanType === 'construction') {
          await dispatch(updateConstructionLoan.request(modifiedValues));
          await dispatch(getConstructionLoanDetails.request({ userId }));
        } else if (loanType === 'term') {
          await dispatch(updateLoan.request(modifiedValues));
          await dispatch(loansValues.request({ userId }));
        }
        setShowNotesForm(false);
      }
    });
  }

  function handleRiskNoteSubmit(loanData) {
    form.validateFields(async (err, values) => {
      if (!err) {
        const modifiedValues = {
          ...loanData,
          riskManagement: {
            ...loanData.riskManagement,
            notes: values.riskManagementNotes,
          },

        };
        if (loanType === 'construction') {
          await dispatch(updateConstructionLoan.request(modifiedValues));
          await dispatch(getConstructionLoanDetails.request({ userId }));
        } else if (loanType === 'term') {
          await dispatch(updateLoan.request(modifiedValues));
          await dispatch(loansValues.request({ userId }));
        }
        setShowNotesForm(false);
      }
    });
  }
  function handleAchievementNoteSubmit(loanData) {
    form.validateFields(async (err, values) => {
      if (!err) {
        const modifiedValues = {
          ...loanData,
          achievementTracking: {
            ...loanData.achievementTracking,
            notes: values.achievementTrackingNotes,
          },
        };
        if (loanData === 'construction') {
          await dispatch(updateConstructionLoan.request(modifiedValues));
          await dispatch(getConstructionLoanDetails.request({ userId }));
        } else if (loanType === 'term') {
          await dispatch(updateLoan.request(modifiedValues));
          await dispatch(loansValues.request({ userId }));
        }
        setShowNotesForm(false);
      }
    });
  }

  const penalty = data?.prePaymentPenaltyType === 'interestRateDifferential' ? 'Interest Rate Differential'
    : data?.prePaymentPenaltyType === '3-month-interest' ? '3-Month Interest'
      : data?.prePaymentPenaltyType === 'yield-maintenance' ? 'Yield Maintenance'
        : data?.prePaymentPenaltyType === 'noPenalty' ? 'No Penalty'
          : '-';
  const todaysDate = new Date();
  const monthDifferenceOfLoan = monthDiff(new Date(firstPaymentDate), todaysDate);
  const loanAmortSchedule = amortSchedCA(originalLoanAmount, allInRate, term, amortization);
  // will modify loanAmortSchedule array, add Payment Date property and pass this as a datasource
  let loanAmortScheduleWithDate = [];

  // only proceed when loanAmortSchedule is truthy and array size more than 0
  if (loanAmortSchedule && loanAmortSchedule.length > 0) {
    // set starting from firstPaymentDate and add one day as it give one day behind because if UTC conversion
    const now = moment(data.firstPaymentDate);
    now.add(1, 'day');
    loanAmortSchedule.forEach((amortObj, loanIndex, arr) => {
      arr[loanIndex] = {
        ...amortObj,
        'payment date': now.format('MMMM DD, YYYY'),
      };
      // add 1 month
      now.add(1, 'months');
    });
    loanAmortScheduleWithDate = loanAmortSchedule;
  }
  const startOfMonthDate = moment().startOf('month').format('MMMM DD, YYYY');
  const endOfMonthDate = moment().endOf('month').format('MMMM DD, YYYY');
  const currentMonthAmortSchedule = loanAmortScheduleWithDate
    && loanAmortScheduleWithDate.length > 0 && loanAmortScheduleWithDate.filter((el) => startOfMonthDate && endOfMonthDate
      && moment(el['payment date'])
        .isBetween(moment(startOfMonthDate), moment(endOfMonthDate), null, []));
  const currentMonthInterestPayment = currentMonthAmortSchedule[0]?.interest;
  const internalNOI = propertyDetails?.internalNOI;
  const updatedCurrentOutstandingLoanAmount = monthDifferenceOfLoan !== 0 ? (monthDifferenceOfLoan < loanAmortSchedule.length // eslint-disable-line
    ? loanAmortSchedule[monthDifferenceOfLoan] && loanAmortSchedule[monthDifferenceOfLoan]['principal remaining']
    : loanAmortSchedule[loanAmortSchedule.length - 1] && loanAmortSchedule[loanAmortSchedule.length - 1]['principal remaining'])
    : originalLoanAmount;
  const remainingAmount = loanAmortSchedule && loanAmortSchedule.length > 0
    ? loanAmortSchedule[loanAmortSchedule.length - 1]['principal remaining']
    : '-';

  const chgType = data.chargeType === 'firstCharge' ? 'First Charge' // eslint-disable-line
    : data.chargeType === 'secondCharge' ? 'Second Charge'// eslint-disable-line
      : data.chargeType === 'thirdCharge' ? 'Third Charge'// eslint-disable-line
        : '-';

  const displayKeys = data?.outStandingLoanAmount && data.outStandingLoanAmount.map((item) => Object.keys(item));
  const displayValues = data?.outStandingLoanAmount && data.outStandingLoanAmount.map((item) => `$${Object.values(item)}`);
  function displayGuarantors(arrOfFields, lengthOfguarantors) {
    let finalArr = [];
    if (lengthOfguarantors > 1) {
      for (let i = 1; i <= lengthOfguarantors; i += 1) {
        finalArr = [...finalArr, ...arrOfFields];
      }
    } else {
      return arrOfFields;
    }
    return finalArr;
  }

  const lenderContact = lenderContacts?.filter((item) => item.id === data.leadLenderContactId);

  return data && lenders
    ? (
      <Card
        id={id}
        key={index}
        bodyStyle={{ padding: '10px' }}
        title={
          <Row>
            {data.authorizedLoanAmount
              ? <Col span={12}>
                <Title level={3} margin={'0 0 0 0'}>Construction Loan(s)</Title>
              </Col>
              : <Col span={12}>
                <Title level={3} margin={'0 0 0 0'}>Term Loan</Title>
              </Col>}
            <Col span={12} style={{ textAlign: 'right' }}>
              {/* <Title level={3} margin={'0 0 0 0'}>{`${index + 1}~e~~`}</Title> */}
            </Col>
          </Row>
        }>

        <TabsAntD defaultActiveKey='1' className='titleTabs'>

          {/* Loan Details  */}
          <TabPane tab='Loan Details' key='1'>
            <Row>
              {data.originalLoanAmount
                ? <Col span={6}>
                  {['Loan Name:', 'Loan ID:', 'First Payment Date:',
                    'Term:', 'Amortization:', 'Pre-Payment Penalty Type:', 'Original Loan Amount:',
                    'Outstanding Amount:', 'Interest Coverage Ratio:', 'Cross Collateralized Loan:', 'Cross Collateral Property:']
                    .map(
                      (label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>,
                    )}
                </Col>
                : <Col span={6}>
                  {['Loan Name:', 'Loan ID:', 'First Payment Date:',
                    'Term:', 'Amortization:', 'Pre-Payment Penalty Type:', 'Original Loan Amount:',
                    'Outstanding Amount:', 'Interest Coverage Ratio:', 'Cross Collateralized Loan:',
                    'Cross Collateral Property:', ' ', 'LOAN DRAWS:']
                    //  .concat(displayKeys)
                    //  .concat('Outstanding Amount')
                    .map(
                      (label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>,
                    )}
                </Col>}
              {data.originalLoanAmount
                ? <Col span={6}>
                  {[data?.loanName ? data.loanName : '-',
                  data?.loanId ? data.loanId : '-',
                  data?.firstPaymentDate ? data.firstPaymentDate.split('T')[0] : '-',
                  data?.term ? data.term : '-',
                  data?.amortization ? data.amortization : 'Interest Only',
                  data?.prePaymentPenaltyType && penalty,
                  data?.originalLoanAmount
                    ? `$${checkNum(String(data.originalLoanAmount))}`
                    : '-',
                  // Current Outstanding Loan amount is auto calculated and is not an input field
                  // !data.amortization
                  //   ? `$${checkNum(String(data.originalLoanAmount))}`
                  //   : `$${checkNum(String(updatedCurrentOutstandingLoanAmount))}`,
                  // '  ',
                  // Since the otstndng loan amnt is auto Clcltd I hd to entr empty space sp that int cov.
                  // ratio is nt displyed for outstanding loan amt.
                  data.outstandingLoanAmount ? `$${checkNum(String(data.outstandingLoanAmount))}` : '-',
                  currentMonthInterestPayment && internalNOI !== 0 ? `${(internalNOI / currentMonthInterestPayment).toFixed(5)}x` : '-',
                  data.crossCollaterizedLoan === 'no' ? 'No' : 'Yes',
                  data.crossCollateralLoan ? data.crossCollateralLoan : '-',
                  ]
                    .map((value, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{value || <br />}</FormItem>)}
                </Col>
                : <Col span={6}>
                  {[data?.loanName ? data.loanName : '-',
                  data?.loanId ? data.loanId : '-',
                  data?.firstPaymentDate ? data.firstPaymentDate.split('T')[0] : '-',
                  data?.term ? data.term : '-',
                  'Amortization',
                  data?.prePaymentPenaltyType ? data?.prePaymentPenaltyType : '-',
                  data?.authorizedLoanAmount ? `$${checkNum(String(data.authorizedLoanAmount))}` : '-'].concat(displayValues)
                    // .concat(data.totalOutstandingLoanAmount ? `$${checkNum(String(data.totalOutstandingLoanAmount))}` : '-')
                    .concat(currentMonthInterestPayment && internalNOI !== 0 ? `${(internalNOI / currentMonthInterestPayment).toFixed(5)}x` : '-',
                  data?.crossCollaterizedLoan ? data?.crossCollaterizedLoan.toUpperCase() : '-',
                  data?.crossCollateralPropertyName ? data?.crossCollateralPropertyName : '-')
                    .map((value, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{value || <br />}</FormItem>)

                  }
                </Col>
              }
              {data?.originalLoanAmount
                ? <Col span={6}>
                  {['Rate Type:', 'Facility Index:', 'Spread:', 'All-In Rate:', 'Interest Rate Floor', 'Interest Rate Cap', 'LTV:',
                    'DSCR:', 'Recourse:', 'Expiry Amount:', 'Charge Type:']
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>
                : <Col span={6}>
                  {['Rate Type:', 'Facility Index:', 'Spread:', 'All-In Rate:', 'Interest Rate Floor', 'Interest Rate Cap', 'LTV:', 'LTC:',
                    'DSCR:', 'Recourse:', 'Expiry Amount:', 'Charge Type:']
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>}
              {data.originalLoanAmount
                ? <Col span={6}>
                  {[data.rateType ? formatItems(data?.rateType) : '-',
                    data.facilityIndexId ? formatItems(data.facilityIndexId) : '-',
                    data.spread && data.spread !== undefined ? `${data.spread.toFixed(2)}%` : '-',
                    data.allInRate ? `${data.allInRate.toFixed(2)}%` : '-',
                    data.interestRateFloor ? `${data.interestRateFloor.toFixed(2)}%` : '-',
                    data.interestRateCap ? `${data.interestRateCap.toFixed(2)}%` : '-',
                    data.ltv ? `${data.ltv.toFixed(2)}%` : '-',
                    data.dscr ? `${data.dscr.toFixed(2)}x` : '-',
                    data.recourse
                      ? formatItems(data.recourse) : '-',
                    data && data.originalLoanAmount && remainingAmount ? `$${remainingAmount.toLocaleString()}` : '-',
                    data && data.chargeType && chgType,

                  ].map(
                    (value, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{value || <br />}</FormItem>,
                  )
                  }
                </Col> : <Col span={6}>
                  {
                    [data?.rateType ? formatItems(data.rateType) : '-',
                    data?.facilityIndexType ? data.facilityIndexType.toUpperCase() : '-',
                    data?.spread ? `${data.spread.toFixed(2)}%` : '-',
                    data?.allInRate ? `${data.allInRate.toFixed(2)}%` : '-',
                    data?.interestRateFloor ? `${data.interestRateFloor.toFixed(2)}%` : '-',
                    data?.interestRateCap ? `${data.interestRateCap.toFixed(2)}%` : '-',
                    data?.ltv ? `${data.ltv.toFixed(2)}%` : '-',
                    data?.ltc ? `${data.ltc.toFixed(2)}%` : '-',
                    data?.dscr ? `${data.dscr.toFixed(2)}x` : '-',
                    data?.recourse
                      ? formatItems(data.recourse) : '-',
                    data && data.originalLoanAmount && remainingAmount ? `$${remainingAmount.toLocaleString()}` : '-',
                    data && data.chargeType && chgType,
                    ]
                      .map(
                        (value, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{value || <br />}</FormItem>,
                      )}
                </Col>}
            </Row>
            <br />
            <Row>
              {data.originalLoanAmount
                ? <Col span={6}>
                  {['Lead Lender:', 'Exposure %:', 'Syndicate Lender(s):', 'Exposure %:']
                    .map(
                      (label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>,
                    )}
                </Col>
                : <Col span={6}>
                  {displayKeys?.map(
                    (label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>,
                  )}

                </Col>}
              {data.originalLoanAmount
                ? <Col span={6}>
                  {[
                    lenders?.find((lender) => lender.id === data.lenderId)?.companyName,
                    `${data.leadLenderExposurePercentage}%`,
                    data?.syndicatePartnersId && data.syndicatePartnersId.length
                      ? lenders?.filter((lender) => data.syndicatePartnersId.includes(lender.id))
                        .map((syn) => syn.companyName).join(' , ') : '-',
                    data.syndicateLenderExposurePercentage ? `${data.syndicateLenderExposurePercentage}%` : '-',
                  ]
                    .map((value, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{value || <br />}</FormItem>)}
                </Col>
                : <Col span={6}>
                  {displayValues?.map(
                    (label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>,
                  )}

                </Col>

              }

              {data && data.originalLoanAmount
                ? <Col span={6}>
                  {['Lead Lender Contact', 'Exposure $:', '', 'Exposure $:']
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>

                : <Col span={6}>
                  {['Total Outstanding Loan Amount:']
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>
              }

              {data && data.originalLoanAmount
                ? <Col span={6}>
                  {[
                    data && data.leadLenderContactId && lenderContact?.length > 0
                      ? `${lenderContact[0]?.firstName} ${lenderContact[0]?.lastName}` : '',
                    data ? `$${checkNum(String(data.leadLenderExposureAmount))}` : '-',
                    '   ',
                    data && data.syndicateLenderExposureAmount > 0 ? `$${checkNum(String(data.syndicateLenderExposureAmount))}` : '-',
                  ]
                    .map((value, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{value || <br />}</FormItem>)}
                </Col>
                : <Col span={6}>
                  {[
                    `$ ${data && data.totalOutstandingLoanAmount ? data.totalOutstandingLoanAmount.toLocaleString() : 0}`,
                  ].map((value, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{value || <br />}</FormItem>)}

                </Col>

              }
            </Row>

            {/* Notes Section  */}
            <br />
            {data && !data?.notes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data && data.notes ? data.notes : '')}</p>
                  </Col>
                }
              </Row>
            }

            {data && data?.notes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data && data.notes ? data.notes : '')}</p>
                  </Col>
                }
              </Row>
            }

            {showNotesForm && <Form
              onSubmit={() => handleLoanNoteSubmit(data)}
              layout={'vertical'}
              labelAlign={'left'}
              hideRequiredMark
            >
              <Row gutter={20}>
                <Row>
                  <Col span={24}>
                    <FormItem style={{ margin: '10px 45px 20px 45px' }}>
                      {getFieldDecorator('loanNote', {
                        initialValue: data.notes ? data.notes : '',
                      })(
                        <TextArea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} align='right' >
                    <Button
                      htmlType='button'
                      onClick={() => setShowNotesForm(false)}
                      style={{ marginRight: '10px' }}
                    > Cancel
                    </Button>
                    <Button
                      type='success'
                      htmlType={'submit'}
                      style={{ marginRight: '45px' }}
                    > Save
                    </Button>
                  </Col>
                </Row>

              </Row>

            </Form>
            }

          </TabPane>

          {/* Gaurantor Details   */}

          <TabPane tab='Guarantor Details' key='2'>
            <Row >
              <Col span={6}>
                {data
                  && displayGuarantors(['Guarantor:',
                    'Original Liability Amount:',
                    'Relationship with Borrower:',
                    'Off Balance Sheet Guarantee:',
                    ' ',
                  ], data?.loanOwnership?.length)?.map(
                    (label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>,
                  )}
              </Col>

              <Col span={6}>
                {
                  data.loanOwnership?.map((loan, i) => (
                    <div key={i}>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }}>{loan?.name}</FormItem>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }}>
                        {`$${checkNum(String(((loan?.allocation / 100) * (data?.originalLoanAmount
                          || data?.authorizedLoanAmount)).toFixed(2)))}`}</FormItem>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }}>
                        {loan?.relationshipWithBorrower ? formatItems(loan?.relationshipWithBorrower) : ''}</FormItem>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }}>
                        {loan?.offBalanceSheetGuarantee ? formatItems(loan?.offBalanceSheetGuarantee) : ''}</FormItem>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }} />

                    </div>
                  ))}
              </Col>

              <Col span={6}>
                {
                  displayGuarantors(['Guarantor %:',
                    'Current Liability Amount:',
                    'Type:',
                    'Guarantee After Sold:',
                    ' ',
                  ], data?.loanOwnership?.length)
                    .map(
                      (label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>,
                    )}
              </Col>

              <Col span={6}>
                {
                  data?.loanOwnership?.map((loan, i) => (
                    <div key={i}>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }}>{loan?.allocation}%</FormItem>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }}>
                        {`$${checkNum(String(((loan?.allocation / 100) * (data?.outstandingLoanAmount
                          || data?.totalOutstandingLoanAmount)).toFixed(2)))}`}
                      </FormItem>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }}>{loan?.type === 'joint'
                        ? 'Joint' : loan?.type === 'joint-and-several' ? 'Joint & Several' : '-'}</FormItem>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }}>{loan?.guaranteeAfterAssetSold
                        ? formatItems(loan?.guaranteeAfterAssetSold) : ''}</FormItem>
                      <FormItem margin='35px' style={{ whiteSpace: 'pre' }} />
                    </div>
                  ))}
              </Col>

            </Row>
            <br />
            {data && data?.guarantorNotes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data?.guarantorNotes ? data.guarantorNotes
                      : '')}</p>
                  </Col>
                }
              </Row>
            }
            {data && !data.guarantorNotes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data?.guarantorNotes ? data.guarantorNotes
                      : '')}</p>
                  </Col>
                }
              </Row>
            }

            {showNotesForm && <Form
              onSubmit={() => handleGuarantorNoteSubmit(data)}
              layout={'vertical'}
              labelAlign={'left'}
              hideRequiredMark
            >
              <Row gutter={20}>
                <Row >
                  <Col span={24}>
                    <FormItem style={{ margin: '10px 45px 20px 45px' }}>
                      {getFieldDecorator('notesGuarantor', {
                        initialValue: data?.guarantorNotes ? data.guarantorNotes : '',
                      })(
                        <TextArea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} align='right' >
                    <Button
                      htmlType='button'
                      onClick={() => setShowNotesForm(false)}
                      style={{ marginRight: '10px' }}
                    > Cancel
                    </Button>
                    <Button
                      type='success'
                      htmlType={'submit'}
                      style={{ marginRight: '45px' }}
                    > Save
                    </Button>
                  </Col>
                </Row>

              </Row>

            </Form>
            }

          </TabPane>

          {/* Financial Covenants  */}

          <TabPane tab='Financial Covenants' key='3'>

            {data
            && data.covenants
              && <Row gutter={20} style={{ padding: '10px', marginBottom: '30px' }}>
                <Row gutter={20}>
                  <Col span={6}>
                    <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                      <strong>Loan to Value Percentage:</strong></FormItem>
                  </Col>
                  {
                    data?.covenants?.loanToValuePercentage ? (
                      <Col span={6}>
                        <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                          {`${data?.covenants?.loanToValuePercentage?.toFixed(2)}%`}</FormItem>
                      </Col>
                    )
                      : (
                        <Col span={6}>
                          <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                            -</FormItem>
                        </Col>
                    )
                  }
                  <Col span={6}><FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>LTV Description:</strong></FormItem></Col>
                  {
                    data?.covenants?.ltvDescription ? (
                      <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.ltvDescription}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }

                </Row>

                <Row gutter={20}>
                  <Col span={6}>
                    <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                      <strong>Debt Yield Percentage:</strong></FormItem>
                  </Col>
                  {
                    data?.covenants?.debtYieldPercentage ? (
                      <Col span={6}>
                        <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                          {`${data?.covenants?.debtYieldPercentage?.toFixed(2)}%`}</FormItem>
                      </Col>
                    )
                      : (
                        <Col span={6}>
                          <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                            <p>-</p></FormItem>
                        </Col>
                    )
                  }
                  <Col span={6}><FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>DSCR Description:</strong></FormItem></Col>
                  {
                    data?.covenants?.debtYieldDescription ? (
                      <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.debtYieldDescription}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }
                </Row>

                <Row gutter={20}>
                  <Col span={6}>
                    <FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                      <strong>Events Of Default:</strong>
                    </FormItem>
                  </Col>
                  {
                    data?.covenants?.eventsOfDefault ? (
                      <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.eventsOfDefault}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }
                  <Col span={6}><FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>Occupancy Requirement:</strong></FormItem></Col>
                  {
                    data?.covenants?.occupancyRequirenmentPercentage ? (
                      <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.occupancyRequirenmentPercentage}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }
                </Row>

                <Row gutter={20}>
                  <Col span={6}>
                    <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                      <strong>Debt Service Coverage Ratio:</strong>
                    </FormItem>
                  </Col>
                  {
                    data?.covenants?.debtServiceCoverageRatio ? (
                      <Col span={6}>
                        <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                          {`${data?.covenants?.debtServiceCoverageRatio?.toFixed(2)}x`}
                        </FormItem>
                      </Col>
                    )
                      : (
                        <Col span={6}>
                          <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                            -
                          </FormItem>
                        </Col>
                    )
                  }
                  <Col span={6}><FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>DSCR Description:</strong></FormItem></Col>
                  {
                    data?.covenants?.dscrDescription ? (
                      <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.dscrDescription}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }

                </Row>

                <Row gutter={20}>
                  <Col span={6}><FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>Major Anchor Tenants:</strong></FormItem></Col>
                  {
                    data?.covenants?.majorAnchorTenants ? (
                      <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.majorAnchorTenants}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }
                  <Col span={6}><FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>Extension Options:</strong></FormItem></Col>
                  {
                    data?.covenants?.extensionOptions ? (
                      <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.extensionOptions}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }
                </Row>

                <Row gutter={20}>
                  <Col span={6}
                  ><FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>Lender Definition of NOI used for DSCR:</strong>
                  </FormItem>
                  </Col>
                  {
                    data?.covenants?.noiUsedForDscr ? (
                      <Col span={18}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.noiUsedForDscr}</p></Col>
                    )
                      : (
                        <Col span={18}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }
                </Row>
                <Row gutter={20}>
                  <Col span={6}>
                    <FormItem margin='10px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                      <strong>Debt Yield Percentage:</strong></FormItem>
                  </Col>
                  {
                    data?.covenants?.debtYieldPercentage ? (
                      <Col span={6}>
                        <FormItem margin='10px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                          {`${data?.covenants?.debtYieldPercentage?.toFixed(2)}%`}</FormItem>
                      </Col>
                    )
                      : (
                        <Col span={6}>
                          <FormItem margin='10px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                            -</FormItem>
                        </Col>
                    )
                  }

                  <Col span={6}><FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>Debt Yield Description:</strong></FormItem></Col>
                  {
                    data?.covenants?.debtYieldDescription ? (
                      <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.debtYieldDescription}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }
                </Row>
                <Row>
                  <Col span={6}><FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>Events of Default :</strong></FormItem></Col>
                  {
                    data?.covenants?.eventsOfDefault ? (
                      <Col span={18}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.eventsOfDefault}</p></Col>
                    )
                      : (
                        <Col span={18}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }
                </Row>

                <Row gutter={20}>
                  <Col span={6}><FormItem margin='20px 35px 20px 35px' style={{ whiteSpace: 'pre' }}>
                    <strong>Extension Options:</strong></FormItem></Col>
                  {
                    data?.covenants?.extensionOptions ? (
                      <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>{data?.covenants?.extensionOptions}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 20px 35px' }}>-</p></Col>
                    )
                  }
                  <Col span={6}>
                    <FormItem margin='20px 35px 10px 35px' style={{ whiteSpace: 'pre' }}>
                      <strong>Other Covenants(Legal, Tax, Insurance etc.):</strong>
                    </FormItem>
                  </Col>
                  {
                    data?.covenants?.otherCovenants ? (
                      <Col span={6}><p style={{ margin: '10px 35px 10px 35px' }}>{data?.covenants?.otherCovenants}</p></Col>
                    )
                      : (
                        <Col span={6}><p style={{ margin: '10px 35px 10px 35px' }}>-</p></Col>
                    )
                  }
                </Row>

              </Row>
            }
            <br />
            {data
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data && data.covenants && data.covenants.notes ? data.covenants.notes
                      : '')}</p>
                  </Col>
                }
              </Row>
            }

            {showNotesForm && <Form
              onSubmit={() => handleCovNoteSubmit(data)}
              layout={'vertical'}
              labelAlign={'left'}
              hideRequiredMark
            >
              <Row gutter={20}>
                <Row>
                  <Col span={24}>
                    <FormItem style={{ margin: '10px 45px 20px 45px' }}>
                      {getFieldDecorator('covenantsNotes', {
                        initialValue: data?.covenants?.notes ? data.covenants?.notes : '',
                      })(
                        <TextArea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} align='right' >
                    <Button
                      htmlType='button'
                      onClick={() => setShowNotesForm(false)}
                      style={{ marginRight: '10px' }}
                    > Cancel
                    </Button>
                    <Button
                      type='success'
                      htmlType={'submit'}
                      style={{ marginRight: '45px' }}
                    > Save
                    </Button>
                  </Col>
                </Row>

              </Row>

            </Form>
            }

          </TabPane>
          {/* Reporting Obligations  */}

          <TabPane tab='Reporting Obligations' key='4'>
            <Row>
              {data.originalLoanAmount
                ? <Col span={6}>
                  {[' ', 'Borrower:', 'Tax:', 'Insurance:', 'ESA', 'Valuation / Appraisal', 'Guarantor Financials',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>
                : <Col span={6}>
                  {[' ', 'Borrower:', 'Tax:', 'Insurance:', 'ESA', 'Valuation / Appraisal', 'Guarantor Financials']
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>}

              {data.originalLoanAmount
                ? <Col span={6}>
                  {['From Date',
                    data?.reportingObligation?.borrowerFinancials?.fromDate?.split('T')[0],
                    data?.reportingObligation?.tax?.fromDate?.split('T')[0],
                    data?.reportingObligation?.insurance?.fromDate?.split('T')[0],
                    data?.reportingObligation?.environmentals?.fromDate?.split('T')[0],
                    data?.reportingObligation?.valuation?.fromDate?.split('T')[0],
                    data?.reportingObligation?.guarantorFinancials?.fromDate?.split('T')[0],
                  ]
                    .map((label, i) => (i !== 0
                      ? <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>
                      : <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>))}
                </Col>
                : <Col span={6}>
                  {['From Date',
                    data?.reportingObligation?.borrowerFinancials?.fromDate?.split('T')[0],
                    data?.reportingObligation?.tax?.fromDate?.split('T')[0],
                    data?.reportingObligation?.insurance?.fromDate?.split('T')[0],
                    data?.reportingObligation?.environmentals?.fromDate?.split('T')[0],
                    data?.reportingObligation?.valuation?.fromDate?.split('T')[0],
                    data?.reportingObligation?.guarantorFinancials?.fromDate?.split('T')[0],
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>}

              {data.originalLoanAmount
                ? <Col span={6}>
                  {['Period',
                    data ? data.reportingObligation?.borrowerFinancials?.period?.trim() : '-',
                    data ? data.reportingObligation?.tax?.period?.trim() : '-',
                    data ? data.reportingObligation?.insurance?.period?.trim() : '-',
                    data ? data.reportingObligation?.environmentals?.period?.trim() : '-',
                    data ? data.reportingObligation?.valuation?.period?.trim() : '-',
                    data ? data.reportingObligation?.guarantorFinancials?.period?.trim() : '-',
                  ]
                    .map((label, i) => (i !== 0
                      ? <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>
                      : <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>))}
                </Col>
                : <Col span={6}>
                  {['Period',
                    data ? data.reportingObligation?.borrowerFinancials?.period?.trim() : '-',
                    data ? data.reportingObligation?.tax?.period?.trim() : '-',
                    data ? data.reportingObligation?.insurance?.period?.trim() : '-',
                    data ? data.reportingObligation?.environmentals?.period?.trim() : '-',
                    data ? data.reportingObligation?.valuation?.period?.trim() : '-',
                    data ? data.reportingObligation?.guarantorFinancials?.period?.trim() : '-',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>}
            </Row>
            <br />
            {data && !data.reportingObligation?.notesReporting
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data.reportingObligation?.notes
                      ? data.reportingObligation.notes : '')}</p>
                  </Col>
                }
              </Row>
            }

            {showNotesForm && <Form
              onSubmit={() => handleRepNoteSubmit(data)}
              layout={'vertical'}
              labelAlign={'left'}
              hideRequiredMark
            >
              <Row gutter={20}>
                <Row>
                  <Col span={24}>
                    <FormItem style={{ margin: '10px 45px 20px 45px' }}>
                      {getFieldDecorator('reportingNotes', {
                        initialValue: data.reportingObligation?.notes
                          ? data.reportingObligation.notes : '',
                      })(
                        <TextArea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} align='right' >
                    <Button
                      htmlType='button'
                      onClick={() => setShowNotesForm(false)}
                      style={{ marginRight: '10px' }}
                    > Cancel
                    </Button>
                    <Button
                      type='success'
                      htmlType={'submit'}
                      style={{ marginRight: '45px' }}
                    > Save
                    </Button>
                  </Col>
                </Row>

              </Row>

            </Form>
            }

          </TabPane>

          {/* Fees and Pricing Details  */}
          <TabPane tab='Fees and Pricing Details' key='5'>
            <Row>

              {data.originalLoanAmount
                ? <Col span={6}>
                  {['LOI Fee:', 'Standby Fee:', 'Legal Fee:', 'Arrangement Fee:', 'Agency Fee:', 'Third Party Costs:',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>
                : <Col span={6}>
                  {['LOI Fee:', 'Standby Fee:', 'Legal Fee:', 'Arrangement Fee:', 'Agency Fee:', 'Third Party Costs:',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>}

              {data.originalLoanAmount
                ? <Col span={6}>
                  {[
                    data.feesAndPricingDetails?.loiFee ? `$${checkNum(String(data.feesAndPricingDetails?.loiFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.standbyFee ? `$${checkNum(String(data.feesAndPricingDetails?.standbyFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.legalFee ? `$${checkNum(String(data.feesAndPricingDetails?.legalFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.arrangementFee ? `$${checkNum(String(data.feesAndPricingDetails?.arrangementFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.agencyFee ? `$${checkNum(String(data.feesAndPricingDetails?.agencyFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.thirdPartyCosts ? `$${checkNum(String(data.feesAndPricingDetails?.thirdPartyCosts.toFixed(2)))}`
                      : '-',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>)}
                </Col>
                : <Col span={6}>
                  {[
                    data.feesAndPricingDetails?.loiFee ? `$${checkNum(String(data.feesAndPricingDetails?.loiFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.standbyFee ? `$${checkNum(String(data.feesAndPricingDetails?.standbyFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.legalFee ? `$${checkNum(String(data.feesAndPricingDetails?.legalFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.arrangementFee ? `$${checkNum(String(data.feesAndPricingDetails?.arrangementFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.agencyFee ? `$${checkNum(String(data.feesAndPricingDetails?.agencyFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.thirdPartyCosts ? `$${checkNum(String(data.feesAndPricingDetails?.thirdPartyCosts.toFixed(2)))}`
                      : '-',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>)}
                </Col>}

              {data.originalLoanAmount
                ? <Col span={6}>
                  {['Amendment Fee:', 'Extension Fee:', 'Commitment Fee:', 'Upfront Fee:', 'Broker Fee:', 'Other Fees:',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>
                : <Col span={6}>
                  {['Amendment Fee:', 'Extension Fee:', 'Commitment Fee:', 'Upfront Fee:', 'Broker Fee:', 'Other Fees:',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>}

              {data.originalLoanAmount
                ? <Col span={6}>
                  {[
                    data.feesAndPricingDetails?.amendmentFee ? `$${checkNum(String(data.feesAndPricingDetails?.amendmentFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.extensionFee ? `$${checkNum(String(data.feesAndPricingDetails?.extensionFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.commitmentFee ? `$${checkNum(String(data.feesAndPricingDetails?.commitmentFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.upfrontFee ? `$${checkNum(String(data.feesAndPricingDetails?.upfrontFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.brokerFee ? `$${checkNum(String(data.feesAndPricingDetails?.brokerFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.otherFee ? `$${checkNum(String(data.feesAndPricingDetails?.otherFee.toFixed(2)))}` : '-',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>)}
                </Col>
                : <Col span={6}>
                  {[
                    data.feesAndPricingDetails?.amendmentFee ? `$${checkNum(String(data.feesAndPricingDetails?.amendmentFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.extensionFee ? `$${checkNum(String(data.feesAndPricingDetails?.extensionFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.commitmentFee ? `$${checkNum(String(data.feesAndPricingDetails?.commitmentFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.upfrontFee ? `$${checkNum(String(data.feesAndPricingDetails?.upfrontFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.brokerFee ? `$${checkNum(String(data.feesAndPricingDetails?.brokerFee.toFixed(2)))}` : '-',
                    data.feesAndPricingDetails?.otherFee ? `$${checkNum(String(data.feesAndPricingDetails?.otherFee.toFixed(2)))}` : '-',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>)}
                </Col>}
            </Row>
            <br />
            {data && data.authorizedLoanAmount && !data.feesAndPricingDetails?.notes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data && data.feesAndPricingDetails
                      && data.feesAndPricingDetails.notes
                      ? data.feesAndPricingDetails.notes : '')}</p>
                  </Col>
                }
              </Row>
            }
            {data && data.authorizedLoanAmount && data.feesAndPricingDetails?.notes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data && data.feesAndPricingDetails
                      && data.feesAndPricingDetails.notes
                      ? data.feesAndPricingDetails.notes : '')}</p>
                  </Col>
                }
              </Row>
            }
            {data && data.originalLoanAmount && !data.feesAndPricingDetails?.notes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data && data.feesAndPricingDetails
                      && data.feesAndPricingDetails.notes
                      ? data.feesAndPricingDetails.notes : '')}</p>
                  </Col>
                }
              </Row>
            }
            {data && data.originalLoanAmount && data.feesAndPricingDetails?.notes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data && data.feesAndPricingDetails
                      && data.feesAndPricingDetails.notes
                      ? data.feesAndPricingDetails.notes : '')}</p>
                  </Col>
                }
              </Row>
            }

            {showNotesForm && <Form
              onSubmit={() => handleFeesNoteSubmit(data)}
              layout={'vertical'}
              labelAlign={'left'}
              hideRequiredMark
            >
              <Row gutter={20}>
                <Row>
                  <Col span={24}>
                    <FormItem style={{ margin: '10px 45px 20px 45px' }}>
                      {getFieldDecorator('feesAndPricingNotes', {
                        initialValue: data && data.feesAndPricingDetails?.notes
                          ? data.feesAndPricingDetails.notes : '',
                      })(
                        <TextArea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} align='right' >
                    <Button
                      htmlType='button'
                      onClick={() => setShowNotesForm(false)}
                      style={{ marginRight: '10px' }}
                    > Cancel
                    </Button>
                    <Button
                      type='success'
                      htmlType={'submit'}
                      style={{ marginRight: '45px' }}
                    > Save
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Form>
            }

          </TabPane>

          {/* Risk Management */}

          <TabPane tab='Risk Management' key='6'>
            <Row>
              {data.originalLoanAmount
                ? <Col span={6}>
                  {['Refinance Candidate:', 'Lease At Expiry:', 'IRR:', 'NPV:',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>
                : <Col span={6}>
                  {['Refinance Candidate:', 'Lease At Expiry:', 'IRR:', 'NPV:',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>}

              {data.originalLoanAmount
                ? <Col span={6}>
                  {[
                    data && data.riskManagement && data.riskManagement.refinanceCandidate ? data.riskManagement.refinanceCandidate : '-',
                    data && data.riskManagement && data.riskManagement.leaseRolloverPercentageAtExpiry
                      ? `${data.riskManagement?.leaseRolloverPercentageAtExpiry}%` : '-',
                    data && data.riskManagement && data.riskManagement.irr ? `${data.riskManagement?.irr}%` : '-',
                    data && data.riskManagement && data.riskManagement.npv ? `$${checkNum(String(data.riskManagement.npv.toFixed(2)))}` : '-',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>)}
                </Col>
                : <Col span={6}>
                  {[
                    data && data.riskManagement && data.riskManagement.refinanceCandidate ? data.riskManagement.refinanceCandidate : '-',
                    data && data.riskManagement && data.riskManagement.leaseRolloverPercentageAtExpiry
                      ? `${data.riskManagement?.leaseRolloverPercentageAtExpiry}%` : '-',
                    data && data.riskManagement && data.riskManagement.irr ? data.riskManagement.irr : '-',
                    data && data.riskManagement && data.riskManagement.npm ? data.riskManagement?.npv : '-',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>)}
                </Col>}
            </Row>
            <br />

            <br />
            {data && !data.riskManagement?.notes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data?.riskManagement?.notes
                      ? data.riskManagement.notes : '')}</p>
                  </Col>
                }
              </Row>
            }
            {data && data.riskManagement?.notes
              && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                <Col span={20}>
                  <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                </Col>
                <Col span={4} align='right'>
                  <FormItem
                    margin='35px'
                    style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                    onClick={editableNotes}><strong >Edit</strong>
                  </FormItem>
                </Col>
                {!showNotesForm
                  && <Col span={24}>
                    <p style={{ margin: '0px 35px 20px 35px' }}>{(data?.riskManagement?.notes
                      ? data.riskManagement.notes : '')}</p>
                  </Col>
                }
              </Row>
            }

            {showNotesForm && <Form
              onSubmit={() => handleRiskNoteSubmit(data)}
              layout={'vertical'}
              labelAlign={'left'}
              hideRequiredMark
            >
              <Row gutter={20}>
                <Row>
                  <Col span={24}>
                    <FormItem style={{ margin: '10px 45px 20px 45px' }}>
                      {getFieldDecorator('riskManagementNotes', {
                        initialValue: data && data.riskManagement?.notes
                          ? data.riskManagement.notes : '',
                      })(
                        <TextArea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />,
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} align='right' >
                    <Button
                      htmlType='button'
                      onClick={() => setShowNotesForm(false)}
                      style={{ marginRight: '10px' }}
                    > Cancel
                    </Button>
                    <Button
                      type='success'
                      htmlType={'submit'}
                      style={{ marginRight: '45px' }}
                    > Save
                    </Button>
                  </Col>
                </Row>

              </Row>

            </Form>
            }

          </TabPane>

          {/* Achievement Tracking */}

          {data && data.authorizedLoanAmount
            && <TabPane tab='Achievement Tracking' key='7'>
              <Row>
                <Col span={8}>
                  {[
                    'Pre Sales Threshold', 'Cost to Complete', 'Contingencies Remaining', 'Project Completion Percentage',
                    'Fixed Price Threshold:',
                  ]
                    .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>)}
                </Col>

                {

                  <Col span={8}>
                    {[
                      data && data.achievementTracking && data.achievementTracking.preSalesThreshold
                        ? data.achievementTracking.preSalesThreshold : '-',
                      data && data.achievementTracking && data.achievementTracking.costToComplete
                        ? data.achievementTracking.costToComplete : '-',
                      data && data.achievementTracking && data.achievementTracking.contingenciesRemaining
                        ? data.achievementTracking.contingenciesRemaining : '-',
                      data && data.achievementTracking && data.achievementTracking.projectCompletionPercentage
                        ? `${data.achievementTracking.projectCompletionPercentage}%` : '-',

                    ]
                      .map((label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{label}</FormItem>)}
                  </Col>
                }
                <br />
              </Row>
              <Row>

                <Col span={6}>

                  <Row>
                    {data && data?.achievementTracking?.fixedPriceContractThreshold
                      ? data.achievementTracking?.fixedPriceContractThreshold?.map((item, ind) => (
                        <div key={ind}>
                          <Col span={6}>
                            {['Name:'].map(
                              (label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>,
                            )}
                          </Col>
                          <Col span={6}>
                            {[item?.name]
                              .map((value, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{value || <br />}</FormItem>)}
                          </Col>
                          <Col span={6}>
                            {['Amount'].map(
                              (label, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}><strong>{label}</strong></FormItem>,
                            )}
                          </Col>
                          <Col span={6}>
                            {[`$${item?.amount}`]
                              .map((value, i) => <FormItem margin='35px' key={i} style={{ whiteSpace: 'pre' }}>{value || <br />}</FormItem>)}
                          </Col>
                        </div>
                      ))
                      : null}
                  </Row>
                </Col>

              </Row>

              <br />
              {data && !data.achievementTracking?.notes
                && <Row gutter={20} style={{ borderTop: '1px solid #e8e8e8' }}>
                  <Col span={20}>
                    <FormItem margin='35px' style={{ whiteSpace: 'pre' }}><strong>Note:</strong></FormItem>
                  </Col>
                  <Col span={4} align='right'>
                    <FormItem
                      margin='35px'
                      style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#4B67EC' }}
                      onClick={editableNotes}><strong >Edit</strong>
                    </FormItem>
                  </Col>
                  {!showNotesForm
                    && <Col span={24}>
                      <p style={{ margin: '0px 35px 20px 35px' }}>{(data.achievementTracking?.notes
                        ? data.achievementTracking.notes : 'No Notes to Show')}</p>
                    </Col>
                  }
                </Row>
              }

              {showNotesForm && <Form
                onSubmit={() => handleAchievementNoteSubmit(data)}
                layout={'vertical'}
                labelAlign={'left'}
                hideRequiredMark
              >
                <Row gutter={20}>
                  <Row>
                    <Col span={24}>
                      <FormItem style={{ margin: '10px 45px 20px 45px' }}>
                        {getFieldDecorator('achievementTrackingNotes', {
                          initialValue: data && data.achievementTracking?.notes
                            ? data.achievementTracking?.notes : '',
                        })(
                          <TextArea allowClear autoSize={{ minRows: 2, maxRows: 6 }} />,
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} align='right' >
                      <Button
                        htmlType='button'
                        onClick={() => setShowNotesForm(false)}
                        style={{ marginRight: '10px' }}
                      > Cancel
                      </Button>
                      <Button
                        type='success'
                        htmlType={'submit'}
                        style={{ marginRight: '45px' }}
                      > Save
                      </Button>
                    </Col>
                  </Row>
                </Row>

              </Form>
              }
            </TabPane>
          }
        </TabsAntD>
      </Card>
    )
    : '';
}

export default Form.create({ name: 'loan-notes' })(CarouselCard);
